// import { Select, Button, Modal } from "antd";
import { Select, Button } from "antd";
import { useState } from "react";
import styles from "./accesoryFinder.module.scss";
import useFilter, { arrayToPath } from "grandus-lib/hooks/useFilter";
import { get, find, map } from "lodash";
import Link from "next/link";
// import useSWR from "swr";

const CATEGORY_NAME = "tokok-es-hatlapok";
const PARAM_MODEL_ID = 253;
const PARAM_BRAND_ID = 28;

const AccessoryFinder = () => {
  // const [openModal, setOpenModal] = useState(false);
  const [brandData, setBrandData] = useState();
  const [modelData, setModelData] = useState();

  // const { data: staticPage, isValidating } = useSWR(
  //   `/api/lib/v1/statics/ako-zistit-model-telefonu`,
  //   (url) => fetch(url).then((r) => r.json()),
  //   {
  //     revalidateOnFocus: false,
  //     revalidateOnReconnect: false,
  //     shouldRetryOnError: false,
  //   }
  // );

  let parameters = [];
  if (brandData) {
    parameters = [PARAM_BRAND_ID, brandData];
  }

  const { filter, isLoading } = useFilter({
    category: CATEGORY_NAME,
    parameters: parameters,
  });

  const brands = find(
    get(filter, "parameters", []),
    (parameter) => parameter?.id == PARAM_BRAND_ID
  );

  const models = find(
    get(filter, "parameters", []),
    (parameter) => parameter?.id == PARAM_MODEL_ID
  );

  return (
    <div id={styles.finder}>
      <h2>Expressz tartozékkereső</h2>
      <Select
        name={"accessoryBrand"}
        style={{
          width: "100%",
          marginBottom: "15px",
        }}
        allowClear={true}
        defaultActiveFirstOption={false}
        placeholder={"Válasszon márkát"}
        loading={isLoading}
        showSearch={true}
        onChange={(value) => {
          setBrandData(value);
          if (modelData) {
            setModelData(null);
          }
        }}
      >
        {map(get(brands, "values", []), (brand, index) => {
          return (
            <Select.Option
              key={`accessory-brand-${brand?.id}-${index}`}
              value={get(brand, "v2-value")}
            >
              {get(brand, "value")}
            </Select.Option>
          );
        })}
      </Select>

      <Select
        name={"accessoryModel"}
        style={{
          width: "100%",
          marginBottom: "15px",
        }}
        allowClear={true}
        defaultActiveFirstOption={false}
        placeholder={brandData ? "Válasszon modellt" : "---"}
        loading={isLoading}
        disabled={brandData ? false : true}
        showSearch={true}
        value={modelData}
        onChange={(value) => {
          setModelData(value);
        }}
      >
        {map(get(models, "values", []), (model, index) => {
          return (
            <Select.Option
              key={`accessory-brand-${model?.id}-${index}`}
              value={get(model, "v2-value")}
            >
              {get(model, "value")}
            </Select.Option>
          );
        })}
      </Select>
      <div className={styles.footer}>
        {/* <span onClick={() => setOpenModal(true)} className={styles.link}>
          Hogyan lehet megtudni a telefon modelljét?
        </span> */}
        <Link
          href="/kategoria/[category]/[[...parameters]]"
          as={`/kategoria/${CATEGORY_NAME}/${
            brandData && modelData
              ? arrayToPath([
                  get(brands, "urlTitle", PARAM_BRAND_ID),
                  brandData,
                  get(models, "urlTitle", PARAM_MODEL_ID),
                  modelData,
                ])
              : ""
          }`}
        >
          <Button
            size="small"
            type="primary"
            loading={isLoading}
            disabled={brandData && modelData && !isLoading ? false : true}
          >
            Kijelző
          </Button>
        </Link>
      </div>
      {/* <Modal
        title={get(staticPage, "title")}
        visible={openModal}
        footer={false}
        onCancel={() => setOpenModal(false)}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: isValidating
              ? "nahrávam informácie ..."
              : get(staticPage, "content"),
          }}
        />
      </Modal> */}
    </div>
  );
};

export default AccessoryFinder;
